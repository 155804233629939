import React from 'react';
import utils from '../../utils';
import * as yt from './iframeApi';
import { FaRegPlayCircle } from 'react-icons/fa';
import { useStore } from '../../store';

export function YoutubeEmbedPopup({ url, title, posterUrl }) {
  const { dispatch } = useStore();

  const videoId = yt.getYoutubeCode(url);
  const posterImageUrl = utils.site.resourcePath(posterUrl || yt.thumbnailMax(videoId));
  const [popupIndex, setPopupIndex] = React.useState(0);

  function openVideo() {
    setPopupIndex(popupIndex + 1);
    dispatch({ type: 'SET_POPUP', payload: { sources: [
      // <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      'https://www.youtube.com/watch?v=' + videoId
    ], popupIndex: popupIndex + 1} });
  }
  return (
    <div className="ytEmdPopup__poster" style={{ backgroundImage: utils.css.bgUrlStyle(posterImageUrl) }} onClick={openVideo}>
      <FaRegPlayCircle className="ytEmdPopup__playIcon" />
    </div>
  );
}
