import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core'
import env from '../../env';
import utils from '../../utils';
import { useGet, ErrorPopup, Loading } from '../../components';
import style from './feedStyle';
import {FaRegCaretSquareRight, FaRegClone} from 'react-icons/fa';

export function InstagramFeed(props){
  const get = useGet();

  React.useEffect(() => {
    get.send(env.apiBase + `/api/instagram/GetInstagramFeeds`);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  console.log(get)
  const data = get.response;
  if (data.length === 0) return null
  return (
    <Feeds posts={data} />
  );
}

function Feeds({posts}) {
  const numOfTileLines = 2;
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = {xs:2, sm:3, md:4, lg:4, xl:5, xxl:6}
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 6);
  console.log(bp, numOfTileLines, numOfPost)
  const displayingPosts = posts.length > numOfPost ? posts.slice(0, numOfPost) : posts;

  return (
    <div css={style.tiles}>
      {displayingPosts.map((item, index) =>
      <Feed key={index} item={item} />)}
    </div>
  )
}

function Feed({item}) {
  return (
    <div css={style.tile}>
      {/* {item.link} */}
      <a href={item.link} target="_blank" rel="noopener noreferrer" css={style.tileImage}>
        <img src={utils.site.resourcePath(`/instagram/${item.filename}`)} alt={item.filename} />
        {item.type === 'video' && <FaRegCaretSquareRight css={style.tileIcon} />}
        {item.type === 'carousel' && <FaRegClone css={style.tileIcon} />}
      </a>
    </div>
  )
}