import * as React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

function DateSeparateInput({ control, handleChange, formOption }) {
    //const { name, label, value, errors, style } = control;
    const { name, label, errors, style, helpText, attrs } = control;
    const thisYear = (new Date()).getFullYear();
    //console.log(name, value)
    const [year, setYear] = React.useState('')
    const [month, setMonth] = React.useState('')
    const [day, setDay] = React.useState('')

    //const { template = 'bootstrap', usePlaceholder = false } = formOption;
    const fieldId = utils.random.shortId();
    const { usePlaceholder = false } = formOption;
    const fieldLabel = getLabel(control, formOption);
    //console.log(label, name + '|'+ fieldLabel)
    //const placeHolder = usePlaceholder ? fieldLabel : null;
    // let valueStr = value === 0 ? 0 : (value || "");
    // if (type === 'date' && value) {
    //     valueStr = utils.date.formatYYYYMMDD(value);
    // }

    function handleChangeDay(e) {
        setDay(e.target.value)
        handleChangeInner(year, month, e.target.value)
    }

    function handleChangeMonth(e) {
        setMonth(e.target.value)
        handleChangeInner(year, e.target.value, day)
    }

    function handleChangeYear(e) {
        setYear(e.target.value)
        handleChangeInner(e.target.value, month, day)
    }

    function handleChangeInner(yyyy, mm, dd) {
        control.incomplete = !(yyyy !== '' && mm !== '' && dd !== '')
        const newVal = yyyy + '-' + mm + '-' + dd;
        //console.log(newVal)
        //mimic event
        handleChange({
            persist: () => { },
            target: { name: name, value: newVal, checked: false }
        }, control)
    }

    //const monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const selectStyle = { width: 'auto', display: 'inline-block', marginRight: '0.5em' };

    const fromYear = style && style.recentYear ? thisYear : thisYear - 10;
    const recentYear = style && style.recentYear ? style.recentYear : 100;
    return (
        <div className="ff ff--dateSeperate form-group">
            {!usePlaceholder && fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className="ff__input">
                <select style={selectStyle} id={`day_${fieldId}`} value={day} onChange={(e) => handleChangeDay(e)}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')} {...attrs}>
                    <option value=''>Day</option>
                    {[...Array(31).keys()].map(i => i + 1).map(d => <option key={d} value={d}>{d}</option>)}
                </select>
                <select style={selectStyle} id={`month_${fieldId}`} value={month} onChange={(e) => handleChangeMonth(e)}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')} {...attrs}>
                    <option value=''>Month</option>
                    {[...Array(12).keys()].map(m => <option key={m} value={m + 1}>{months[m]}</option>)}
                </select>
                <select style={selectStyle} id={`year_${fieldId}`} value={year} onChange={(e) => handleChangeYear(e)}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')} {...attrs}>
                    <option value=''>Year</option>
                    {[...Array(recentYear).keys()].map(y => fromYear - y).map(y => <option key={y} value={y}>{y}</option>)}
                </select>
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>
    )
}

export default DateSeparateInput;