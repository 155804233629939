//import uuid from 'uuid/v4';
import shortid from 'shortid';
import * as formModelHelper from './formModelHelper';

//#region helpers
function findIndexById(id) {
  for (let i = 0; i < this.controls.length; i++) {
    if (this.controls[i].id === id) return i;
  }
  return -1;
}
//#endregion

const group = (controls) => {
  const controlArray = [];
  //console.log('group', controls, typeof controls);
  if (controls && typeof controls === 'object') {
    Object.keys(controls).forEach(key => {
      controlArray.push(abstractControl(key, controls[key]))
    })
  }

  const ctrl = {
    controlType: 'group',
    id: shortid(),
    controls: controlArray,
    find: (name) => controlArray.find((x) => x.name === name),
    customValueFn: null, // function
    disableValidators: false
  }
  ctrl.getValue = () => formModelHelper.getValue(ctrl)
  ctrl.controls.forEach(x => x.parent = ctrl)

  return ctrl
}

const array = (controls) => {
  //console.log('array', controls);
  const ctrl = {
    controlType: 'array',
    id: shortid(),
    controls: controls,
    findIndexById: findIndexById
  }
  ctrl.getValue = () => formModelHelper.getValue(ctrl)
  ctrl.controls.forEach(x => x.parent = ctrl)

  return ctrl
}

const abstractControl = (name, controlObject) => {
  //console.log('abstractControl', name, controlObject);
  if (!controlObject) return null;
  // array or group
  if (controlObject.controlType) {
    controlObject.name = name;
    return controlObject;
  } else {
    return control(name, controlObject)
  }
}

const control = (name, [value, validators, controlOptions]) => {
  //const { label, type, options, style } = controlOptions || {};
  //console.log(value, validators, label, type, options)
  return {...{
    controlType: 'control',
    id: shortid(),
    name: name,
    value: value,
    validators: validators || [],
    touched: false,
    incomplete: false,
    findValidator: (type) => (validators || []).find((x) => x.type === type),
    // label: label,
    // type: type,
    // options: options,
    // style: style
  }, ...controlOptions};
}

export default { group, array }