import React from 'react';
import { loadJs } from '../../lib/net-loader';

export function useInstagram() {
  const [embedStatus, setEmbedStatus] = React.useState(null);

  React.useEffect(() => {
    loadEmbedJs()
  })

  function loadEmbedJs() {
    if (typeof window !== `undefined`) {
      //console.log('instgrm effect', embedStatus, window.instgrm);
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
      else if (!embedStatus && embedStatus !== 'loading') {
        setEmbedStatus('loading');
        loadJs("https://www.instagram.com/embed.js").then(data => {
          //console.log('instgrm loaded', data);
          if (window.instgrm) {
            window.instgrm.Embeds.process();
            setEmbedStatus('loaded');
          }
        })
      }
    }
  }

  return { loadEmbedJs: loadEmbedJs }
}