import * as React from "react";
import siteConfig from '../../../config';

const iconColors = (siteConfig && siteConfig.iconColors) || { primary: "#eee", secondary: "#000" }

function SvgLoading(props) {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <circle
        cx={50}
        cy={50}
        r={29.973}
        fill="none"
        stroke={iconColors.primary}
        strokeWidth={8}
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.8518518518518516s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.9259259259259258s"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.8518518518518516s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.9259259259259258s"
        />
      </circle>
      <circle
        cx={50}
        cy={50}
        r={8.673}
        fill="none"
        stroke={iconColors.secondary}
        strokeWidth={8}
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.8518518518518516s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.8518518518518516s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
        />
      </circle>
    </svg>
  );
}

export default SvgLoading;