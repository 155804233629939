import TextInput from './TextInput';
import RadioListInput from './RadioListInput';
import TextareaInput from './TextareaInput';
import CheckboxInput from './CheckboxInput';
import CheckboxListInput from './CheckboxListInput';
import SelectInput from './SelectInput';
import HiddenInput from './HiddenInput';
//import HtmlInput from './HtmlInput';
import DateSeparateInput from './DateSeparateInput';
import FileInput from './FileInput';

const Components = { TextInput, RadioListInput, TextareaInput, CheckboxInput, CheckboxListInput, SelectInput, HiddenInput, DateSeparateInput, FileInput };

const capitalize = expression => expression.charAt(0).toUpperCase() + expression.slice(1);

export function getControl(control, formOption) {
  let Component = null;
  if (control.type === 'number' || control.type === 'email' || control.type === 'date' || control.type === 'password' || control.type === 'tel')
    Component = TextInput;
  else if (control.type === "dob")
    Component = DateSeparateInput;
  else
    Component = Components[capitalize(control.type || '') + 'Input'];

  return Component;
}

