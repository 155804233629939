import { css } from '@emotion/core'
//import { mq } from '../../cssInJs'

export default {
  book__widget : css({
    maxWidth: '960px',
    marginLeft:'auto',
    marginRight:'auto',
    marginTop:'1rem',
    marginBottom:'1rem'
  })
}