import React from 'react'
import cmsUtils from '../../utils/cmsUtils'
import utils from '../../../utils'
import { YoutubeNew } from '../youtubeNew/view'
// import { YoutubeNew } from 'cms/items/youtubeNew/view'

export function VideoPanel(props) {
  const item = props.item
  const cmsOption = props.cmsOption || {}
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard'
  const videoCode = cmsUtils.payload(item, 'YoutubeCode')
  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl', null)
  const playerId = 'ytBanner-' + item.itemId.substr(0, 8)
  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId,
  }
  const canPlayVideo = () => utils.css.screenWidth() >= 0
  const cssClass = utils.classNames(
    'cms_item',
    'youtubePanel',
    item.cssClass || item.anchorName || ''
  )
  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <YoutubeNew
        video={video}
        canPlayVideo={canPlayVideo}
        posterImageUrl={utils.site.resourcePath(posterFrameUrl)}
      />
    </div>
  )
}
