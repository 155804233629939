import React from 'react';

export function Button({onClick, status, children, btnStyle='primary', className}) {
  let buttonStyle = 'btn-' + btnStyle;
  return (
    <button className={`btn ${buttonStyle} ${className}`} onClick={onClick} type="button" disabled={status === 'pending'}>
      {status === 'pending' && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
      {children}
    </button>
  );
}