import utils from '../../utils';
import React from 'react';
import { hasRequiredValidator } from './validatorHelper';

export function getLabel(control, formOption) {
  //console.log(label, label == null, label === null, name, label || name)
  const { label, name } = control
  const requiredMark = hasRequiredValidator(control) && (formOption.requiredMark || '*')
  if (label === null) return "";
  return <>
    {label || utils.text.toFieldName(name)}
    {requiredMark && <span className="req">{requiredMark}</span>}
  </>;
}

export function getLabelAsString(control, formOption) {
  //console.log(label, label == null, label === null, name, label || name)
  const { label, name } = control
  const requiredMark = hasRequiredValidator(control) && (formOption.requiredMark || '*')
  if (label === null) return "";
  
  return (label || utils.text.toFieldName(name)) + (requiredMark ? requiredMark : '');
}