import { createValidator, getNameForValidator } from '../validatorHelper';

export function Length(message = null, opt = null) {
  return createValidator('email', message, opt, function(control, e, validateOpt, validator) {
    if (validateOpt.triggeredByChange && control.incomplete) return null;
    return !isValidLength(control.value, validator) ? (message || (getNameForValidator(control.label, control.name) + ' - Invalid length.')) : null
  })
}


export const isValidLength = (text, validator) => {
  const length = (text || '').length;
  if (validator.min && length < validator.min) return false;
  if (validator.max && length > validator.max) return false;
  return true;
}
