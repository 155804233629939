import { createValidator, getNameForValidator } from '../validatorHelper';
import utils from '../../../utils';

export function ValidateDate(message = null, opt = null) {
  return createValidator('date', message, opt, function(control, e, validateOpt) {
    if (validateOpt.triggeredByChange && control.incomplete) return null;
    return !isDate(control.value) ? (message || (getNameForValidator(control.label, control.name) + ' is invalid.')) : null
  })
}

export function PastDate(message = null, opt = null) {
  return createValidator('past-date', message, opt, function(control, e, validateOpt) {
    if (validateOpt.triggeredByChange && control.incomplete) return null;
    return !isPast(control.value) ? (message || (getNameForValidator(control.label, control.name) + ' is invalid.')) : null
  })
}

function isDate(val) {
  if (val) {
    const date = utils.date.toDate(val);
    return !!date;
  }
  return false
}

function isPast(val) {
  if (val) {
    const date = utils.date.toDate(val);
    //console.log(date, typeof date)
    if (date){
      const now = new Date();
      return date.getTime() < now.getTime();
    }
  }
  return false
}
