import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    background: variables.darkBlueColor,
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [70, null, 110],
    marginTop: ['3rem', null, '3.5rem'],
    transition:'all .2s'
  })),
  // logoScrolled: css(mq({
  //   height: [22, null, 45],
  //   marginTop: [null, null, '0rem']
  // })),

  logoScrolled: css(mq({
    img : css(mq({
      height: [35, null, 45],
      marginTop:'0rem'
    }))
  })),
}