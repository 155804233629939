import utils from '../../utils';

export function handleElementLink(el, externalHandler) {
  // externalHandler = (url) => {
  //   if (typeof window !== `undefined`) window.location.href = url;
  // }

  const link = utils.url.getLinkFromElement(el);
  const linkInfo = utils.url.getLinkInfo(link);
  //console.log(link, linkInfo)
  if (!linkInfo) return { handled: false, linkInfo: linkInfo };

  if (linkInfo.type === 'hash') {
    utils.ui.scrollTo(linkInfo.url);
  } else if (linkInfo.type === 'tel' || linkInfo.type === 'mailto') {

  } else if (linkInfo.type === 'external' && externalHandler) {
    // let the external handler process.
    externalHandler(linkInfo.url);
    return { handled: true, linkInfo: linkInfo };;
  }
  return { handled: false, linkInfo: linkInfo };
}