import React from 'react'; 
import { Link } from 'react-router-dom';
import utils from '../../utils';
import { handleElementLink } from './handleLink';

export function SiteLink(props) {
  const { children, to, target, onClick, ...other } = props;

  function goToLink(e) {
    //console.log(e, e.target);
    if (onClick) onClick(e);
    const processed = handleElementLink(e.target);
    //console.log(processed)
    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return utils.url.isExternalLink(to) ?
    <a href={to} target="_blank" rel="noopener noreferrer" onClick={(e) => goToLink(e)} {...other}>{children}</a>
    :
    <Link to={to} onClick={(e) => goToLink(e)} {...other}>{children}</Link>
    ;
}
