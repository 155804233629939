/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import utils from "../../utils";
import { getBookWidgetModel } from "./bookWidgetModel";
import { useForm } from "../../form";
import { Button } from "../../components";
import cmsUtils from "../../cms/utils/cmsUtils";

export function BookWidget(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "bookWidget",
    item.cssClass || item.anchorName || ""
  );

  const preSelect = cmsUtils.payload(item, "PreSelect") || "";

  const model = getBookWidgetModel({ preSelect: preSelect });
  const form = useForm(model, {});

  const render = (name) => form.renderControl(name, null);

  function onSubmit(e) {
    form.validateForm(e, () => {
      //const value = { ...form.getValue(), emailReceiver: emailReceiver };
      const value = { ...form.getValue() };

      const availableFrom = new Date(value.available_from);
      const _availableFrom =
        availableFrom.getFullYear() +
        "-" +
        (availableFrom.getMonth() + 1) +
        "-" +
        availableFrom.getDate();

      const availableTo = new Date(value.available_to);
      const _availableTo =
        availableFrom.getFullYear() +
        "-" +
        (availableTo.getMonth() + 1) +
        "-" +
        availableTo.getDate();

      let requestUrl = "https://bookingsau.newbook.cloud/moontabaycp/index.php";
      requestUrl = requestUrl + "?available_from=" + _availableFrom;
      requestUrl = requestUrl + "&available_to=" + _availableTo;
      requestUrl = requestUrl + "&adults=" + value.adults;
      requestUrl = requestUrl + "&children=" + value.children;
      requestUrl = requestUrl + "&infants=" + value.infants;

      if (value.force_category_id !== "") {
        requestUrl =
          requestUrl + "&force_category_id=" + value.force_category_id;
      }
      window.open(requestUrl, "_self");
    });
  }

  if (cmsOption.isCmsEdit) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> Book Widget Item</b>
        </pre>
      </div>
    );
  }

  return (
    <div css={style.book__widget} className={cssClass} id={item.anchorName}>
      <div className='form-row'>
        <div className={`mb-2 ${preSelect === "" ? "col-md-4" : "col-md-4"}`}>
          {render("available_from")}
        </div>
        <div className={`mb-2 ${preSelect === "" ? "col-md-4" : "col-md-4"}`}>
          {render("available_to")}
        </div>
        <div className='col-md-4 mb-2'>{render("force_category_id")}</div>
      </div>
      <div className='form-row'>
        <div className='col-md-4 mb-2'>{render("adults")}</div>
        <div className='col-md-4 mb-2'>{render("children")}</div>
        <div className='col-md-4 mb-2'>{render("infants")}</div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions checkBtn'>
            <Button onClick={onSubmit}> CHECK AVAILABILITY </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
