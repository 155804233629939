import  utils from '../../utils';

export const MAX = 100000;
export const MIN = -100000;

export function getScrollProgress(el) {
  if (typeof window !== `undefined`) {
    const scroll = window.scrollY || window.pageYOffset
    if (!el) {
      console.log('not exists', el)
      return {
        progress: 0,
        top: scroll
      }
    }
    const boundsTop = el.getBoundingClientRect().top + scroll

    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
      height: window.innerHeight
    }

    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
      height: el.clientHeight
    }

    //const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    // const status = {
    //   viewport: viewport,
    //   bound: bounds,
    //   inViewport: (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
    //   || (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    // }

    //console.log((viewport.bottom - bounds.top) / (bounds.height + viewport.height))
    return {
      progress: (viewport.bottom - bounds.top) / (bounds.height + viewport.height),
      top: (viewport.bottom - bounds.top) / (viewport.height)
    }
  } else {
    return {
      progress: 0,
      top: 0
    }
  }
}

//au.setClass(section, 'animated', pr, [0.3, au.MAX])
export function setClass(el, className, pr, range)
{
  if (pr >= range[0] && pr <= range[1]) {
    utils.css.addClass(el, className);
  } else {
    utils.css.removeClass(el, className);
  }
}

export function prVal(min, max, pr, range = [0, 1]) {
  //console.log(min, max, typeof min, typeof to)
  if (pr < range[0]) return min;
  if (pr > range[1]) return max;
  return min + (max - min) * ((pr - range[0])/(range[1] - range[0]));
}

export function transform(el, x, y) {
  el.style.transform = 'translate(' + x + ',' + y + ')';
}

export function removeTransform(el) {

}

// requestAnimationFrame
// const raf =
//   (<any>window).requestAnimationFrame ||
//   (<any>window).webkitRequestAnimationFrame ||
//   (<any>window).mozRequestAnimationFrame ||
//   function (callback) {
//     (<any>window).setTimeout(callback, 1000 / 60)
//   }
export function getScreenSize() {
  const w = utils.css.screenWidth()
  const h = utils.css.screenHeight()
  return { width: w, height: h };
}
