const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  primaryColor: '#29abe2',
  fontColor: '#454949',
  bgColor: '#ebebeb',
  darkBlueColor:'#102744',
  ...overloading
}
export default variables;