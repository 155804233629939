import React from 'react';
import { useRouter } from './components';
import { useHistoryStore } from './components';
import { useParallax } from './components';
import utils from './utils';

export function AppRouteChanged() {
  const { path } = useRouter();
  const parallax = useParallax();
  const history = useHistoryStore();
  
  React.useLayoutEffect(() => {
    console.log('route changed', path)
    if (typeof window !== `undefined`) {
      parallax.reset();
      const hash = window.location.hash;
      history.push({path: path})
  
      if (hash) { 
        setTimeout(() => {
          const anchor = utils.ui.findElement(hash);
          if (anchor)
            utils.ui.scrollTo(anchor, 500);
        }, 500)
      } else {
        window.scrollTo(0, 0);
      }
    }
    // eslint-disable-next-line
  }, [path]);

  return null;
}
