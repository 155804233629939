import React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

function SelectInput({ control, handleChange, formOption }) {
    const { name, label, type, value, errors, style, options, helpText, attrs } = control;
    const fieldId = utils.random.shortId();
    const fieldLabel = getLabel(control, formOption);
    //console.log(value, typeof value)
    return (
        <div className={`ff ff--select form-group ${style && style.className}`}>
            {fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className="ff__input">
                <select id={fieldId} name={name} type={type} value={value === null? '' : value } onChange={(e) => handleChange(e, control)}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')} {...attrs}>
                    {options && options.map(option => <option key={option.value} value={option.value} disabled={!!option.disabled}>{option.name}</option>)}
                </select>
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>
    )
}

export default SelectInput;

