import { fb, formModelHelper } from "../../lib/form";

export function getBookWidgetModel(data) {
  const today = new Date();
  const minDateForAvailableTo = new Date();
  minDateForAvailableTo.setDate(today.getDate() + 2);

  const _categories = [
    { name: "All", value: "" },
    { name: "Norfolk (Pet Friendly) Cabins", value: "21" },
    // { name: 'Self Contained Cabin', value: "6" },
    // { name: 'Economy Cabin', value: "34" },
    // { name: 'Ensuite Sites', value: "2" },
    { name: "Drive Thru Sites", value: "32" },
    { name: "Beach Front Powered Sites", value: "31" },
    { name: "Lower Powered Sites", value: "30" },
    { name: "Lower Unpowered Sites", value: "7" },
    { name: "Upper Powered Sites", value: "3" },
    { name: "Queen Room", value: "35" },
    { name: "Family Room", value: "36" },
    { name: "Twin Share Room Ground", value: "37" },
    { name: "Twin Share Room Upstairs", value: "38" },
    { name: "3-Bedroom Jetty View Villa (8 Berth)", value: "43" },
    { name: "2-Bedroom Jetty View Accessible Villa (5 Berth)", value: "47" },
    { name: "2-Bedroom Jetty View Villa (6 Berth)", value: "44" },
    { name: "2-Bedroom Beach View Accessible Villa", value: "45" },
    { name: "2-Bedroom Beach View Villa (6 Berth)", value: "46" },
  ];

  const model = fb.group({
    available_from: [
      data.available_from || today,
      [],
      {
        label: "Arrival Date",
        type: "date",
        attrs: {
          min:
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate(),
        },
        onBeforeUpdateValue: (control, formModel) => {
          const _minDateForAvailableTo = new Date(control.value);
          _minDateForAvailableTo.setDate(_minDateForAvailableTo.getDate() + 2);

          const _availableTo = formModelHelper.findControl(
            "available_to",
            formModel
          );
          _availableTo.attrs.min =
            _minDateForAvailableTo.getFullYear() +
            "-" +
            (_minDateForAvailableTo.getMonth() + 1) +
            "-" +
            _minDateForAvailableTo.getDate();
          _availableTo.value = _minDateForAvailableTo;
        },
      },
    ],
    available_to: [
      data.available_to || minDateForAvailableTo,
      [],
      {
        label: "Departure Date",
        type: "date",
        attrs: {
          min:
            minDateForAvailableTo.getFullYear() +
            "-" +
            (minDateForAvailableTo.getMonth() + 1) +
            "-" +
            minDateForAvailableTo.getDate(),
        },
      },
    ],
    force_category_id: [
      data.preSelect || "",
      [],
      { label: "Accommodation Type", type: "select", options: _categories },
    ],
    adults: [
      data.adults || 1,
      [],
      { label: "Adults", type: "number", attrs: { min: 1 } },
    ],
    children: [
      data.children || 0,
      [],
      { label: "Children", type: "number", attrs: { min: 0 } },
    ],
    infants: [
      data.infants || 0,
      [],
      { label: "Infants", type: "number", attrs: { min: 0 } },
    ],
  });
  return model;
}
