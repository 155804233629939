import React from "react";

function SvgNarrowRight(props) {
  return (
    <svg viewBox="0 0 100 240" {...props}>
      <path
        fill="currentColor"
        d="M26 20l50 100-50 100v-10l44-90-44-90z"
      />
    </svg>
  );
}

export default SvgNarrowRight;
