//#region conversion between array and object
export const convertToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const convertToKeyValues = (array, key, value) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};
//#endregion

//#region array helper functions
export const moveItem = (list, fromIndex, toIndex) => {
  //const list = Array.from(array);
  const [removed] = list.splice(fromIndex, 1);
  list.splice(toIndex, 0, removed);

  return list;
};

export const removeItem = (list, index) => {
  //const list = Array.from(array);
  list.splice(index, 1);

  return list;
};

export const insertItem = (list, index, item) => {
  //const list = Array.from(array);
  list.splice(index, 0, item);

  return list;
};


export const find = (list, fn) => {
  if (!list) return null;
  for (let i = 0; i < list.length; i++) {
    if (fn(list[i])) {
      return list[i];
    }
  }
};

export const findIndex = (list, fn) => {
  if (!list) return -1;
  for (let i = 0; i < list.length; i++) {
    if (fn(list[i])) {
      return i;
    }
  }
};

export const contains = (list, fn) => {
  return findIndex(list, fn) >= 0;
}

export const sum = (list, fn) => {
  if (!list) return 0;
  let total = 0;
  for (let i = 0; i < list.length; i++) {
    total += fn(list[i]) || 0
  }
  return total
}

export const equal = (list1, list2, fn) => {
  if (!list1 && !list2) return true;
  if (!list1 || !list2) return false;
  if (list1.length !== list2.length) return false;
  for (let i = 0; i < list1.length; i++) {
    let found = false;
    for (let j = 0; j < list2.length; j++) {
      if(fn(list1[i], list2[i])) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}
//#endregion

//#region sorting helpers
export const caseInsensitiveCompare = function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

// put the null items at the end.
export const compareIgnoreEmpty = function (asc) {
  return asc ?
    function (a, b) {
      return (a === null || isNaN(a)) - (b === null || isNaN(b)) || +(a > b) || -(a < b);
    }
    :
    function (a, b) {
      return (a === null || isNaN(a)) - (b === null || isNaN(b)) || -(a > b) || +(a < b);
    }
}
//#endregion