import { createValidator, getNameForValidator } from '../validatorHelper';

export function Required(message = null, opt = null) {
  return createValidator('required', message, opt, function(control, e, validateOpt) {
    if (validateOpt.triggeredByChange && control.incomplete) return null;
    return isEmpty(control.value) ? (message || (getNameForValidator(control.label, control.name) + ' is required.')) : null
  })
}

function isEmpty(val) {
  if (Array.isArray(val))
    return val.length === 0;
  return !val && val !== 0;
}
