import { css } from '@emotion/core'
import { mixin, mq } from '../../cssInJs'

export default {
  tiles: css(mq({
    marginLeft: [0, -5, null, -10],
    marginRight: [0, -5, null, -10],
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  })),
  tile: css(mq({
    flexBasis: ['50%', '33.33%', '25%', '25%', '20%', '16.66%'],
    padding: [0, 5, null, 10],
    cursor: 'pointer'
  })),
  tileImage: css({
    position: 'relative',
    display: 'block',
    ':after': { ...mixin.overlay() },
    ':hover, :focus': {
      ':after': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }
    }
  }),
  tileIcon: css({
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 30,
    color: 'white',
    zIndex: 1
  })
}